import "../../App.scss";
export const Footer = (props) => {
  return (
<footer>
    <p>
        <a href="https://patrikdahl.dk" target="_blank" rel="noopener noreferrer">
        Hjemmesiden lavet af Patrik Dahl 
        </a>
        </p>
</footer>  

);
};
